import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import TextField from '@guestyci/foundation/TextField';
import { Col } from '@guestyci/foundation';

import { PaneTag, PaneWrapper, SubTitle } from './Pane.component.styled';

const Pane = ({
  Img,
  subTitle = '',
  title = '',
  bottomText = '',
  isMobile,
}) => (
  <PaneWrapper className="d-flex flex-center">
    <PaneTag className="d-flex flex-1 flex-column flex-center">
      <Col align="center" justify="center">
        <img src={Img} className="max-width-fill pr-6 pl-6" alt="" />
        {title && (
          <TextField variant="h2" className={cn('mb-1 text-center', { 'w-75': isMobile })}>
            {title}
          </TextField>
        )}
        {subTitle && (
          <TextField variant="h4" className={cn('text-center', { 'mb-2': bottomText })}>
            <SubTitle>{subTitle}</SubTitle>
          </TextField>
        )}
        {bottomText && (
          <TextField variant="h5" color="light" className={cn('text-center', { 'mt-20': isMobile })}>
            {bottomText}
          </TextField>
        )}
      </Col>

    </PaneTag>
  </PaneWrapper>
);

Pane.propTypes = {
  Img: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  bottomText: PropTypes.string,
};

export default Pane;
