import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cn from 'classnames';
import { noop } from 'utils/helpers';

const FooterTag = styled.div`
  background-color: ${(props) => props.background || 'var(--white)'};
  bottom: 0;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  color: ${(props) => props.color || 'var(--white)'};
  left: 0;
  line-height: 1.5;
  padding: 15px 0;
`;

const Footer = ({
  className,
  background,
  color,
  children,
  isMobile,
  onClick,
  text,
}) => (
  <FooterTag
    className={cn(
      `d-flex align-items-center justify-content-center w-100 font-weight-bold text-center mt-auto ${className}`,
      {
        'font-size-lg': isMobile,
      },
    )}
    background={background}
    color={color}
    onClick={onClick}
  >
    {text}
    {children}
  </FooterTag>
);

Footer.propTypes = {
  className: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Footer.defaultProps = {
  className: '',
  onClick: noop,
  text: '',
};

export default Footer;
