import styled from 'styled-components';
import { mobile, tablet } from 'constants/css/breakpoints';

export const ExpirationPart = styled.div`
  background: var(--white);
  box-shadow: 0 -3px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 0;
`;

export const ExpiresTag = styled.div`
  letter-spacing: 0.26px;
  text-decoration: underline;
`;

export const ExpiresTitle = styled.div`
  color: #aeb6ba;
  line-height: 1.43;
`;

export const FooterContent = styled.div`
  background-color: var(--white);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  height: 120px;
  padding: 0 50px 0 120px;
  @media ${tablet} {
    padding: 0 20px 0 40px;
  }
  @media ${mobile} {
    height: 110px;
    padding: 0 20px 0 20px;
  }
`;

export const TotalPriceTag = styled.div`
  line-height: 1;
`;
