import React, { useEffect } from 'react';
import { any, isEmpty } from 'ramda';
import { injectStripe } from 'react-stripe-elements';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { getReservationObj } from 'utils/helpers';

import { FormProvider } from '@guestyci/foundation/enums';
import Form from '@guestyci/foundation/Form';
import { Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import useTranslations from '@guestyci/localize/useTranslations';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import {
  FORM_FIELDS,
  FORM_SECTIONS,
  GuestyPayProvider,
  GuestyPayWidget,
  useGuestyPay,
} from '@guestyci/guesty-pay-widget';

import CardDetails from '../Form/Sections/CardDetails';
import CardholderName from '../Form/Sections/CardholderName';
import BillingAddress from '../Form/Sections/BillingAddress';
import validate from '../Form/validation';
import { schema } from './schema';
import { translations } from './translations';
import { formInputTranslations } from '../../translations';
import ReuseCheckbox from '../Form/Stripe/ReuseCheckbox';

const formValidate = (values) => validate(values, schema, { fullMessages: false });

const PaymentsStep = ({
  accountId,
  checkIn,
  checkOut,
  listingId,
  history,
  occupancy,
  processQuotes,
  quotesId,
  reservationId,
  paymentProviderId,
  formRef,
  form,
  setForm,
  stripe,
  providerType,
  setActiveStepSubmit,
  inquiryId,
  bookerId,
  source,
  isPaymentRequired,
  isRequestToBook,
  totalPrice,
  currency,
}) => {
  const [, isCreditCardReusageAvailable] = useFeatureToggle('CreditCardReusage');
  const listingData = {
    endDate: checkOut,
    guestsCount: occupancy,
    reservationId,
    startDate: checkIn,
    listingId,
    providerType,
  };

  const { firstName, lastName, streetAndNumber, city, zipCode, country, ratePlan, email, invoiceTel } =
    form.guestInfoValues;

  const { submit: submitAmaryllisForm } = useGuestyPay();

  const onSubmit = async ({ reusePaymentMethod, ...billingDetails }) => {
    if (stripe) {
      processQuotes({
        accountId,
        data: getReservationObj({
          ...form.guestInfoValues,
          inquiryId,
          source,
          bookerId,
          ratePlanId: ratePlan?.value,
          isPaymentRequired,
          isRequestToBook,
          ...listingData,
        }),
        history,
        quotesId,
        stripe,
        billingDetails,
        paymentProviderId,
        reusePaymentMethod,
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  const onStepSubmit = () => {
    if (providerType === 'stripe') {
      return formRef.current.form.submit;
    }

    const submitForm = () =>
      submitAmaryllisForm({
        quoteId: inquiryId,
        listingId,
        amount: totalPrice,
        currency,
        guest: {
          firstName,
          lastName,
          ...(email && { email }),
          ...(invoiceTel && { phone: invoiceTel }),
        },
      });

    return () => {
      processQuotes({
        accountId,
        data: getReservationObj({
          ...form.guestInfoValues,
          inquiryId,
          source,
          bookerId,
          ratePlanId: ratePlan?.value,
          isPaymentRequired,
          isRequestToBook,
          ...listingData,
        }),
        history,
        quotesId,
        stripe,
        paymentProviderId,
        submitForm,
        inquiryId,
        bookerId,
      });
    };
  };

  useEffect(() => {
    setActiveStepSubmit(onStepSubmit);
    // eslint-disable-next-line
  }, []);

  const [
    payInfoTitle,
    toggleLabelText,
    cardDetailsSectionTitle,
    cardholderNameSectionTitle,
    billingAddressSectionTitle,
  ] = useTranslations(translations);

  const [
    firstNameText,
    lastNameText,
    ,
    ,
    streetText,
    cityText,
    countryText,
    zipCodeText,
    ,
    ,
    stateText,
    creditCardNumberText,
    expDateText,
    cvcText,
  ] = useTranslations(formInputTranslations);

  return (
    <Col spacing={5}>
      <TextField variant="h2">{payInfoTitle}</TextField>
      {providerType === 'amaryllis' ? (
        <GuestyPayWidget
          providerId={paymentProviderId}
          showInitialValuesToggle
          initialValuesToggleLabel={toggleLabelText}
          initialValues={{
            [FORM_FIELDS.FIRST_NAME]: firstName,
            [FORM_FIELDS.LAST_NAME]: lastName,
            [FORM_FIELDS.STREET]: streetAndNumber,
            [FORM_FIELDS.CITY]: city,
            [FORM_FIELDS.ZIP_CODE]: zipCode,
            [FORM_FIELDS.COUNTRY]: {
              label: country.text,
              value: country.value,
            },
          }}
          sections={[
            FORM_SECTIONS.PAYMENT_DETAILS,
            FORM_SECTIONS.CARDHOLDER_NAME,
            FORM_SECTIONS.BILLING_ADDRESS,
          ]}
        />
      ) : (
        <FinalForm
          ref={formRef}
          onSubmit={onSubmit}
          validate={formValidate}
          render={({ handleSubmit, form: { change } }) => (
            <Form provider={FormProvider.Final} fieldInstance={Field} onSubmit={handleSubmit}>
              <FormSpy
                subscription={{
                  submitting: true,
                  valid: true,
                  touched: true,
                }}
                onChange={(state) => {
                  const { submitting, valid, touched } = state;
                  if (setForm) {
                    setForm({
                      ...form,
                      disabled:
                        any((val) => !val, Object.values(touched)) || isEmpty(touched)
                          ? false
                          : submitting || !valid,
                    });
                  }
                }}
              />
              <Col spacing={3}>
                <CardDetails
                  title={cardDetailsSectionTitle}
                  creditCardNumberText={creditCardNumberText}
                  expDateText={expDateText}
                  cvcText={cvcText}
                />
                <CardholderName
                  title={cardholderNameSectionTitle}
                  toggleLabelText={toggleLabelText}
                  firstNameText={firstNameText}
                  lastNameText={lastNameText}
                  change={change}
                  initialValues={{
                    [FORM_FIELDS.FIRST_NAME]: firstName,
                    [FORM_FIELDS.LAST_NAME]: lastName,
                  }}
                />
                <BillingAddress
                  title={billingAddressSectionTitle}
                  toggleLabelText={toggleLabelText}
                  streetText={streetText}
                  cityText={cityText}
                  countryText={countryText}
                  zipCodeText={zipCodeText}
                  stateText={stateText}
                  change={change}
                  initialValues={{
                    [FORM_FIELDS.STREET]: streetAndNumber,
                    [FORM_FIELDS.CITY]: city,
                    [FORM_FIELDS.ZIP_CODE]: zipCode,
                    [FORM_FIELDS.COUNTRY]: country,
                  }}
                />
                {isCreditCardReusageAvailable && <ReuseCheckbox />}
              </Col>
            </Form>
          )}
        />
      )}
    </Col>
  );
};

const PaymentsStepWrapper = (props) => (
    <GuestyPayProvider env={process.env.REACT_APP_CLUSTER}>
      <PaymentsStep {...props} />
    </GuestyPayProvider>
  );

export default injectStripe(PaymentsStepWrapper);
