import { invoicePageInvoiceIntlId } from 'utils/intlIds';

export const formInputTranslations = [
  {
    id: invoicePageInvoiceIntlId('first_name'),
    d: 'First Name',
  },
  {
    id: invoicePageInvoiceIntlId('last_name'),
    d: 'Last Name',
  },
  {
    id: invoicePageInvoiceIntlId('email'),
    d: 'Email',
  },
  {
    id: invoicePageInvoiceIntlId('phone'),
    d: 'Phone number',
  },
  {
    id: invoicePageInvoiceIntlId('street'),
    d: 'Street',
  },
  {
    id: invoicePageInvoiceIntlId('city'),
    d: 'City',
  },
  {
    id: invoicePageInvoiceIntlId('country'),
    d: 'Country',
  },
  {
    id: invoicePageInvoiceIntlId('zip_code'),
    d: 'Zip code',
  },
  {
    id: invoicePageInvoiceIntlId('user_agreement'),
    d: 'I have read and agreed to the',
  },
  {
    id: invoicePageInvoiceIntlId('tos_v2'),
    d: 'Terms and Conditions',
  },
  {
    id: invoicePageInvoiceIntlId('state'),
    d: 'State',
  },
  {
    id: invoicePageInvoiceIntlId('card_number'),
    d: 'Credit card number',
  },
  {
    id: invoicePageInvoiceIntlId('exp_date'),
    d: 'Expiration date',
  },
  {
    id: invoicePageInvoiceIntlId('cvc'),
    d: 'CVC',
  },
];
