import React from 'react';
import cn from 'classnames';

import { Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';

const useStyles = createStyles((theme) => {
  const numberRadius = 14;

  return {
    stepItem: {
      'width': 185,
      '&:not(:last-child)': {
        'position': 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          background: theme.palette.primary.disabled,
          top: numberRadius,
          left: `calc(50% + ${numberRadius}px + 2px)`,
          width: `calc(100% - ${numberRadius * 2}px - 4px)`,
          height: 1,
        },
      },
    },
    number: {
      'width': numberRadius * 2,
      'height': numberRadius * 2,
      'padding': '4px 0',
      'background': theme.palette.primary.disabled,
      'color': theme.palette.text.white,
      'textAlign': 'center',
      'borderRadius': '50%',
      '&.active': {
        background: theme.palette.primary.default,
        color: theme.palette.text.white,
      },
      '&.done': {
        background: theme.palette.background.white,
        color: theme.palette.primary.default,
        border: '1px solid',
        borderColor: theme.palette.primary.default,
      },
    },
  };
});

const Step = ({
  label, isDone, isActive, stepIndex,
}) => {
  const classes = useStyles();

  return (
    <Col spacing={1} align="center" className={classes.stepItem}>
      <TextField
        bold
        variant="h4"
        className={cn(classes.number, {
          active: isActive,
          done: isDone,
        })}
      >
        {stepIndex + 1}
      </TextField>
      <TextField color="default" bold>
        {label}
      </TextField>
    </Col>
  );
};

export default Step;
