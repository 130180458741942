import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import {
  EXPIRED_PAGE,
  INVOICE_PAGE,
  LISTING_PAGE,
  MAIN_PAGE,
  SUCCESS_UNPAID_BOOKING_PAGE,
  SUCCESS_PAID_BOOKING_PAGE,
  BOOK_ALL_LISTING_PAGE,
  BOOK_ALL_INVOICE_PAGE,
  SUCCESS_UNPAID_ALL_BOOKING_PAGE,
} from 'constants/route.constants';
import App from 'App';
import OnBoarding from 'App/OnBoarding';

import { setEnv } from '@guestyci/atomic-design/dist/resource/';
import LocalizationProvider from '@guestyci/localize/LocalizationProvider';
import '@guestyci/foundation/style/styles.css';

import registerServiceWorker from './registerServiceWorker';
import configureStore, { history } from './store';

const store = configureStore();
const cluster = process.env.REACT_APP_CLUSTER;
setEnv(cluster);

const init = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <LocalizationProvider>
          <Router>
            <Route
              path={[
                EXPIRED_PAGE,
                SUCCESS_UNPAID_BOOKING_PAGE,
                SUCCESS_PAID_BOOKING_PAGE,
                SUCCESS_UNPAID_ALL_BOOKING_PAGE,
                MAIN_PAGE,
                BOOK_ALL_INVOICE_PAGE,
                BOOK_ALL_LISTING_PAGE,
                LISTING_PAGE,
                INVOICE_PAGE,
              ]}
              render={(props) => (
                <OnBoarding {...props}>
                  <App />
                </OnBoarding>
              )}
            />
          </Router>
        </LocalizationProvider>
      </ConnectedRouter>
    </Provider>,

    document.getElementById('root'),
  );
};

init();
registerServiceWorker();
