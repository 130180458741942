import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path } from 'ramda';
import Layout from 'App/components/Layout/Layout.component';
import Pane from 'App/components/Pane/Pane.component';
import Footer from 'App/components/Footer/Footer.component';
import MailToString from 'App/components/MailToString/MailToString.component';
import { MOBILE } from 'constants/css/breakpoints';
import { technicalPageIntlId } from 'utils/intlIds';

import useTranslations from '@guestyci/localize/useTranslations';
import { blue700 } from '@guestyci/foundation/theme/colors';

const TechnicalPage = ({
  breakpoint, Img, title, subTitle, bottomText, quotes, isLogo,
}) => {
  const isMobile = breakpoint === MOBILE;
  const email = path(['meta', 'integration', 'incomingEmail'], quotes);

  const translations = [
    {
      id: technicalPageIntlId('for_more_offers'),
      d: 'For more offers please contact us',
    },
  ];

  const [forMoreOffersText] = useTranslations(translations);

  return (
    <Layout withSizeTracker isLogo={isLogo}>
      <>
        <Pane Img={Img} title={title} subTitle={subTitle} isMobile={isMobile} bottomText={bottomText} />
        <Footer background={blue700} isMobile={isMobile}>
          <>
            {forMoreOffersText}
            <MailToString email={email} />
          </>
        </Footer>
      </>
    </Layout>
  );
};

TechnicalPage.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  Img: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  bottomText: PropTypes.string,
  quotes: PropTypes.object,
};

const mapStateToProps = state => ({
  quotes: state.quotes.quotes,
});

export default connect(
  mapStateToProps,
)(TechnicalPage);
