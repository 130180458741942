import { createBrowserHistory } from 'history';
import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import {
  actionSplitterMiddleware,
  apiMiddleware,
  stateFreezer,
} from '@guestyci/atomic-design/dist/reduxRoot/';

import createReducer from './storeConfig/rootReducer';
import rootSaga from './storeConfig/rootSaga';

export const history = createBrowserHistory();

// return configureStore
export default (preloadedState = {}) => {
  const sagaMiddleware = createSagaMiddleware();

  // create the core middleware array
  const coreMiddleware = [
    sagaMiddleware,
    actionSplitterMiddleware,
    apiMiddleware,
    // TODO: temporary disabled, (not displaying redux state changes)
    // logger,
  ];

  // TODO: disable for production?
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // compose the middleware with additional (optional) enhancers,
  const enhancer = applyMiddleware(routerMiddleware(history), ...coreMiddleware);

  const reducer = combineReducers(createReducer(history));

  const store = createStore(stateFreezer(reducer), preloadedState, composeEnhancers(enhancer));

  sagaMiddleware.run(rootSaga);

  return store;
};
