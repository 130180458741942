import React from 'react';
import PropTypes from 'prop-types';
import { getAdjustmentDisplayValue } from 'utils/helpers';

import { Row } from '@guestyci/foundation';
import TextField from '@guestyci/foundation/TextField';
import Icon from '@guestyci/foundation/legacy/Icon';

export const Coupons = ({ coupons }) => coupons.map(({
  name,
  discount,
  discountType,
  couponId,
  currency,
}) => (
  <Row align="center" justify="between" className="mt-2" key={couponId}>
    <TextField className="flex-start-center flex-grow-1" color="default">
      <Icon
        className="mr-1"
        icon="IcoDiscount"
        height={14}
        width={14}
      />
      <span>{name}</span>
    </TextField>
    <TextField className="align-self-end" color="default">
      {getAdjustmentDisplayValue(discount, discountType, currency)}
    </TextField>
  </Row>
));

Coupons.propTypes = {
  coupons: PropTypes.arrayOf(
    PropTypes.shape({
      couponId: PropTypes.string,
      name: PropTypes.string,
      discountType: PropTypes.string,
      couponCode: PropTypes.string,
      discount: PropTypes.number,
    }),
  ),
};

export default Coupons;
