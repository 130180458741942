import React from 'react';
import PropTypes from 'prop-types';
import { differenceInCalendarDays } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import cn from 'classnames';
import {
  groupBy, filter, path, isEmpty,
} from 'ramda';
import { pluralize } from 'utils/helpers';

import TextField from '@guestyci/foundation/TextField';
import Divider from '@guestyci/foundation/Divider';
import { Row } from '@guestyci/foundation';
import useTranslations from '@guestyci/localize/useTranslations';

import Promotions from './Promotions';
import Coupons from './Coupons';
import {
  getTaxesTypes, TAXES_NORMAL_TYPES, taxesTranslations, toTaxType,
} from '../../InformationStep/translations';

const InvoiceItem = ({
  title,
  currencySymbol,
  amount,
  bold,
}) => (
  <Row
    justify="between"
    className={cn({
      'my-3': !bold,
      'my-2': bold,
    })}
    key={title}
  >
    <TextField bold={bold} color="default" overflowEllipsis>{title}</TextField>
    <TextField bold={bold} color="default">
      {currencySymbol}
      {amount}
    </TextField>
  </Row>
);

const InvoiceTotal = ({
  invoiceTitle,
  nightText,
  checkOut,
  checkIn,
  currencySymbol,
  invoiceItems,
  totalText,
  totalPrice,
  price,
  hasInquiriesFlow,
  coupons,
  promotions,
  currentRatePlanQuote,
  taxesText,
  feesText,
  cleaningFeeText,
  subtotalText,
  subtotalBeforeTaxesText,
  totalPayoutText,
}) => {
  const translations = useTranslations(taxesTranslations);
  const taxesTypes = getTaxesTypes(translations);
  const invoiceItemsList = groupBy(item => item.normalType, invoiceItems);
  const fareAccommodation = path(['fareAccommodation'], currentRatePlanQuote);
  const fareAccommodationAdjusted = path(['fareAccommodationAdjusted'], currentRatePlanQuote);
  const fareCleaning = path(['fareCleaning'], currentRatePlanQuote);
  const subTotalPrice = path(['subTotalPrice'], currentRatePlanQuote);

  const taxesList = filter(
    item => !!invoiceItemsList[item],
    TAXES_NORMAL_TYPES,
  );

  const hasPrevTotal = fareAccommodation !== fareAccommodationAdjusted;
  const hasFees = fareCleaning || invoiceItemsList?.AFE;
  const hasTaxes = !!taxesList.length;
  const hasCoupons = !!coupons.length;
  const hasPromotions = !isEmpty(promotions);

  const inquiriesFlowItems = () => (
    <>
      {hasPrevTotal &&
        (<Row justify="end">
          <TextField className="strike-through">
            {currencySymbol}
            {fareAccommodation}
          </TextField>
         </Row>)}
      {
          hasFees && (
            <>
              <InvoiceItem
                bold
                title={subtotalText}
                currencySymbol={currencySymbol}
                amount={fareAccommodationAdjusted}
              />
              <Divider className="my-2" />
              <TextField color="secondary">{feesText}</TextField>
              {!!fareCleaning && (
                <InvoiceItem
                  title={cleaningFeeText}
                  currencySymbol={currencySymbol}
                  amount={fareCleaning}
                />
              )}
              {
                invoiceItemsList?.AFE?.map(({
                  title,
                  amount,
                  type,
                }) => (
                  <InvoiceItem
                    key={`${type}-${title}`}
                    title={title}
                    currencySymbol={currencySymbol}
                    amount={amount}
                  />
                ))
              }
            </>
          )
        }
      {
          hasTaxes && (<>
            <Divider className="my-2" />
            <InvoiceItem
              bold
              title={subtotalBeforeTaxesText}
              currencySymbol={currencySymbol}
              amount={subTotalPrice}
            />
            <Divider className="my-2" />
            <TextField color="secondary">{taxesText}</TextField>
            {taxesList.map(item => {
              if (!invoiceItemsList[item]) {
                return null;
              }
              const {
                title,
                amount,
                type,
                normalType,
              } = invoiceItemsList[item][0];
              const taxType = toTaxType(normalType);

              return (
                <InvoiceItem
                  key={`${type}-${title}`}
                  title={taxesTypes[taxType]}
                  currencySymbol={currencySymbol}
                  amount={amount}
                />
              );
            })}
                       </>)
        }
    </>
  );

  return (
    <>
      <TextField variant="h3" className="mt-5" color="default">{invoiceTitle}</TextField>
      <Row justify="between" className="mt-6">
        <TextField color="default">
          {pluralize(
            nightText,
            differenceInCalendarDays(parseISO(checkOut), parseISO(checkIn)),
          )}
        </TextField>
        <TextField color="default">
          {currencySymbol}
          {price}
        </TextField>
      </Row>
      {
        hasInquiriesFlow && (
          <>
            { (hasPromotions || hasCoupons) && <Divider className="my-2" /> }
            { hasPromotions && <Promotions promotions={promotions} /> }
            { hasCoupons && <Coupons coupons={coupons} /> }
            { (hasPromotions || hasCoupons) && <Divider className="my-2" /> }
          </>
        )
      }
      {
        hasInquiriesFlow
          ? inquiriesFlowItems()
          : invoiceItems
            .map(({
              amount,
              title,
            }) => amount && (
              <InvoiceItem key={title} title={title} amount={amount} currencySymbol={currencySymbol} />
            ))
      }
      <Divider className="my-2" />
      <InvoiceItem
        bold
        title={hasInquiriesFlow ? totalPayoutText : totalText}
        currencySymbol={currencySymbol}
        amount={totalPrice}
      />
    </>
  );
};

InvoiceTotal.propTypes = {
  invoiceTitle: PropTypes.string.isRequired,
  nightText: PropTypes.string.isRequired,
  checkOut: PropTypes.string.isRequired,
  checkIn: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  invoiceItems: PropTypes.array,
  totalText: PropTypes.string.isRequired,
  totalPrice: PropTypes.number,
  price: PropTypes.number,
  hasInquiriesFlow: PropTypes.bool.isRequired,
  coupons: PropTypes.arrayOf(
    PropTypes.shape({
      couponId: PropTypes.string,
      name: PropTypes.string,
      discountType: PropTypes.string,
      couponCode: PropTypes.string,
      discount: PropTypes.number,
    })),
  promotions: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    rule: PropTypes.shape({
      discountType: PropTypes.string,
      discountAmount: PropTypes.number,
    }),
  }),
};

export default InvoiceTotal;
