import React from 'react';
import { CardNumberElement } from 'react-stripe-elements';
import cn from 'classnames';
import { path } from 'ramda';
import { useErrorMessages } from 'hooks/useErrorMessages';

import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';

const useStyles = createStyles(theme => ({
  input: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: `1px solid ${theme.palette.border}`,
    width: '100%',
    height: '46px',
    padding: '0 15px',
  },
  invalid: {
    border: `1px solid ${theme.palette.error.text}`,
    backgroundColor: theme.palette.error.background,
  },
}));

const style = {
  style: {
    base: {
      'fontSize': '12px',
      'letterSpacing': '.2px',
      '::placeholder': {
        fontSize: '12px',
        letterSpacing: '.2px',
      },
    },
  },
};

export default ({ input, meta, ...rest }) => {
  const errorMessages = useErrorMessages();
  const isInvalid = meta.error && meta.touched;
  const classes = useStyles();
  return (
    <>
      <TextField className="mb-2">{rest.label}</TextField>
      <CardNumberElement
        {...input}
        className={cn(
          classes.input,
          { [classes.invalid]: isInvalid },
        )}
        placeholder={rest.placeholder}
        {...style}
      />
      {isInvalid && (
        <TextField color="error">
          {
            errorMessages[meta.error] ||
            path(['value', 'error', 'message'], input) ||
            meta.error
          }
        </TextField>
      )}
    </>
  );
};
