import styled from 'styled-components';
import { tablet } from 'constants/css/breakpoints';

export const SimplifiedListingContent = styled.div`
  color: var(--white);
  opacity: 0;
  transition: opacity 150ms ease-in;
  background-color: rgba(0, 0, 0, .6);
`;

export const SimplifiedListing = styled.div`
  background-image: url('${props => props.img}');
  background-size: cover;
  border-radius: 5px;
  box-shadow: 0 10px 30px 0 rgba(39, 43, 45, 0.1);
  cursor: pointer;
  height: 160px;
  min-height: 160px;
  width: 220px;
  &:hover ${SimplifiedListingContent} {
    transition: opacity 150ms ease-out;
    opacity: 1;
  };
  @media ${tablet} {
    width: 160px;
  };
`;

export const SimplifiedListings = styled.div`
    background: var(--white);
    padding: 170px 50px 5px 55px;
    right: 0;
    top: 0;
    position: sticky;
    height: calc(100vh - var(--footerFixedHeight));
    @media ${tablet} {
        padding: 170px 20px 5px 20px;
    };
`;

export const SimplifiedListingsTitle = styled.div`
  line-height: 1.14;
`;
