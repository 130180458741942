import React from 'react';
import { path } from 'ramda';

import { Col } from '@guestyci/foundation/Layout';
import createStyles from '@guestyci/foundation/createStyles';

import HouseRulesItem from './HouseRulesItem';
import { useHouseRulesData } from './useHouseRulesData';

const useStyles = createStyles(() => ({
  text: {
    maxWidth: 1048,
    whiteSpace: 'break-spaces',
  },
}));

const HouseRules = ({ houseRules }) => {
  const additionalRules = path(['additionalRules'], houseRules);
  const data = useHouseRulesData(houseRules);

  const classes = useStyles();

  return (
    <Col spacing={5}>
      {data.map(rule => (rule.show ?
        <HouseRulesItem key={rule.key} icon={rule.icon} title={rule.title} desc={rule.desc} /> : null))}

      <div className={classes.text}>{additionalRules}</div>
    </Col>
  );
};

export default HouseRules;
