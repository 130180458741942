import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const PaneWrapper = styled.div`
  border-radius: 0 0 0 10px;
  bottom: 0;
  top: 0;
  width: 38.5%;
`;

const ImagesPane = ({
  images, scrollTop, scrollHeight, clientHeight,
}) => {
  const container = useRef();

  useEffect(() => {
    if (container.current) {
      const parentScrollPercent = scrollTop / (scrollHeight - clientHeight);
      const containerMaxScrollHeight = container.current.scrollHeight - container.current.clientHeight;

      container.current.scrollTop = containerMaxScrollHeight * parentScrollPercent;
    }
  }, [scrollTop, scrollHeight, clientHeight]);

  return (
    <PaneWrapper ref={container} className="d-flex flex-column pos-absolute h-100 overflow-hidden">
      {images.map((img, i) => (
        <img
          key={i}
          className={cn('max-width-fill min-width-fill', { 'mt-2': i })}
          alt={`pane-${i}`}
          src={img}
        />
      ))}
    </PaneWrapper>
  );
};

ImagesPane.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
};

export default ImagesPane;
