let prefix = '/apps/send-quotes';
if (process.env.REACT_APP_CLUSTER === 'production') {
  prefix = '';
}

export const MAIN_PAGE = `${prefix}/:quotesId?`;
export const LISTING_PAGE = `${prefix}/:quotesId/listing/:listingId?`;
export const INVOICE_PAGE = `${prefix}/:quotesId/listing/:listingId/invoice`;
export const BOOK_ALL_LISTING_PAGE = `${prefix}/:quotesId/all-listings`;
export const ERROR_PAGE_NOT_AVAILABLE = `${prefix}/:quotesId/all-listings/not-available`;
export const ERROR_PAGE_OFFER_EXPIRED = `${prefix}/:quotesId/all-listings/offer-expired`;
export const ERROR_PAGE_TECH_ISSUE = `${prefix}/:quotesId/all-listings/tech-issue`;
export const BOOK_ALL_INVOICE_PAGE = `${prefix}/:quotesId/all-listings/invoice`;
export const EXPIRED_PAGE = `${prefix}/expired`;
export const SUCCESS_PAID_BOOKING_PAGE = `${prefix}/success-paid`;
export const SUCCESS_UNPAID_BOOKING_PAGE = `${prefix}/success-unpaid`;
export const SUCCESS_UNPAID_ALL_BOOKING_PAGE = `${prefix}/:quotesId/all-listings/success-unpaid-all`;
