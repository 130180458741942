import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Portal from 'App/components/Portal/Portal.component';
import Pane from 'App/components/Pane/Pane.component';
import Layout from 'App/components/Layout/Layout.component';
import { MOBILE } from 'constants/css/breakpoints';
import OopsImg from 'assets/oops.svg';
import { oopsPageIntlId } from 'utils/intlIds';

import useTranslations from '@guestyci/localize/useTranslations';

const Oops = ({ error = false, errorMessage, isMobile }) => {
  const translations = [
    {
      id: oopsPageIntlId('oops_title'),
      d: 'Oops, something went wrong',
    },
    {
      id: oopsPageIntlId('oops_date_title'),
      d: 'Oops, the selected property is not available anymore on the selected dates',
    },
    {
      id: oopsPageIntlId('please_refresh_sub_title'),
      d: 'Please refresh the page',
    },
    {
      id: oopsPageIntlId('please_select_another_sub_title'),
      d: 'Please select another property or contact us for new offer',
    },
  ];

  const [
    oopsTitle,
    oopsDateTitle,
    oopsSubTitle,
    oopsSelectSubTitle,
  ] = useTranslations(translations);

  return (
    error && (
      <Portal>
        <Layout className="w-100 h-100 pos-absolute top-0 left-0 zindex-10">
          <Pane
            Img={OopsImg}
            isMobile={isMobile}
            subTitle={errorMessage ? oopsSelectSubTitle : oopsSubTitle}
            title={errorMessage ? oopsDateTitle : oopsTitle}
          />
        </Layout>
      </Portal>
    )
  );
};
Oops.propTypes = {
  error: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isMobile: state.layout.breakpoint === MOBILE,
  error: state.layout.error,
  errorMessage: state.layout.errorMessage,
});

export default connect(mapStateToProps)(Oops);
