import { useState, useEffect } from 'react';
import {
  differenceInMinutes,
  format,
} from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { noop } from 'utils/helpers';

const getDiffFromTodayInMin = (expiration) => differenceInMinutes(parseISO(expiration), new Date());

export const useExpirationDate = (expiration, cb = noop) => {
  const date = expiration ? parseISO(expiration) : new Date();
  const formattedDate = format(date, 'yyyy-MM-dd HH:mm');
  const [liveExpiration, setLiveExpiration] = useState(
    getDiffFromTodayInMin(formattedDate),
  );

  function updateExpirationIfChanged() {
    const newExpiration = getDiffFromTodayInMin(expiration);
    if (newExpiration <= 0) {
      cb();
    } else if (liveExpiration !== newExpiration) {
      setLiveExpiration(newExpiration);
    }
  }

  useEffect(() => {
    updateExpirationIfChanged();
    const intervalID = setInterval(updateExpirationIfChanged, 1000);

    return () => clearInterval(intervalID);
  }, [expiration, updateExpirationIfChanged]);

  return liveExpiration;
};
