import React from 'react';
import { format, parse } from 'date-fns';
import pluralizeService from 'pluralize';
import {
  filter, identity, join, map, path, sum, hasPath,
} from 'ramda';
import {
  BOOK_ALL_INVOICE_PAGE,
  BOOK_ALL_LISTING_PAGE,
  INVOICE_PAGE,
  LISTING_PAGE,
  MAIN_PAGE,
  SUCCESS_UNPAID_ALL_BOOKING_PAGE,
} from 'constants/route.constants';
import _ from 'lodash';

import { Currency } from '@guestyci/foundation';
import { currencySymbols } from '@guestyci/atomic-design/dist/utils/commonUtility';

export const convertCurrencySymbol = (cString) => {
  if ({}.hasOwnProperty.call(currencySymbols, cString)) {
    return currencySymbols[cString].symbol;
  }
  return 'N/A ';
};

const getVal = (val, suffix) => (val ? pluralize(suffix, val) : '');

export const getFormattedTimeDifference = (minutes) => {
  const fullDays = parseInt(minutes / (60 * 24), 10);
  const fullHours = parseInt(minutes / 60, 10) - fullDays * 24;
  const fullMinutes = minutes % 60;

  return join(
    ' : ',
    filter(identity, [getVal(fullDays, 'day'), getVal(fullHours, 'hour'), getVal(fullMinutes, 'minute')]),
  );
};

export function utcDate(date) {
  const localDate = new Date(date);
  return new Date(+localDate + localDate.getTimezoneOffset() * 60 * 1000);
}

export const constructDateRange = (from, to) => {
  const utcFrom = utcDate(from);
  const utcTo = utcDate(to);
  return utcFrom.getMonth() === utcTo.getMonth()
    ? `${utcFrom.getDate()} - ${format(utcTo, 'd MMM')}`
    : `${format(utcFrom, 'd MMM')} - ${format(utcTo, 'd MMM')}`;
};

export const formattedDateRange = (from, to, FORMAT_SCHEMA = 'MMM dd, yyyy') => {
  const utcFrom = utcDate(from);
  const utcTo = utcDate(to);
  return utcFrom.getMonth() === utcTo.getMonth()
    ? `${utcFrom.getDate()} - ${format(utcTo, FORMAT_SCHEMA)}`
    : `${format(utcFrom, FORMAT_SCHEMA)} - ${format(utcTo, FORMAT_SCHEMA)}`;
};

export const formattedPrice = ({ currency, value }) => {
  const price = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency,
  }).format(value);

  return price;
};

/**
 * Does nothing, no-operation function
 */
export const noop = () => {};

export const pluralize = (string, quantity, showQuantity = true) => pluralizeService(string, quantity, showQuantity);

export const getListingPageUrl = ({ quotesId, listingId, searchParams }) => {
  const withSearch = !searchParams ? '' : `?${searchParams}`;
  const dest = LISTING_PAGE.replace(
    /:quotesId|:listingId\?/g,
    (match) => ({ ':quotesId': quotesId, ':listingId?': listingId })[match],
  );

  return `${dest}${withSearch}`;
};

export const getInvoicePageUrl = ({ quotesId, listingId }) => INVOICE_PAGE.replace(
  /:quotesId|:listingId/g,
  (match) => ({ ':quotesId': quotesId, ':listingId': listingId })[match],
);

export const getInvoiceAllPageUrl = ({ quotesId }) => BOOK_ALL_INVOICE_PAGE.replace(':quotesId', quotesId);

export const getQuotesPageUrl = ({ quotesId }) => MAIN_PAGE.replace(':quotesId', quotesId);

export const getSuccessUnpaidPageUrl = ({ quotesId }) => SUCCESS_UNPAID_ALL_BOOKING_PAGE.replace(':quotesId', quotesId);

export const getAllListingPageUrl = ({ quotesId, listingId }) => BOOK_ALL_LISTING_PAGE.replace(
  /:quotesId|:listingId/g,
  (match) => ({ ':quotesId': quotesId, ':listingId': listingId })[match],
);

export const intlHelper = (prefix) => (suffix) => `send-quotes:${prefix}${suffix}`;

export const mapRateOption = (rate) => {
  const { ratePlan, quote } = rate;
  return {
    label: ratePlan.name,
    value: ratePlan._id,
    data: {
      ...ratePlan,
      quote: quote?.money,
    },
  };
};

/*
 * promo and coupons
 * transforms value according to adjustment type
 */
export const ADJUSTMENT_TYPE_SYMBOLS = {
  percent: '%',
  percantage: '%',
  PERCENTAGE: '%',
  LoS: '%',
  PERCENT: '%',
};

export const getAdjustmentDisplayValue = (value, type, currency) => {
  if (type === 'FIXED') {
    return (
      <span>
        -
        <Currency code={currency} />
        {value}
      </span>
    );
  }

  return value ? `-${value} ${ADJUSTMENT_TYPE_SYMBOLS[type] || ''}` : '';
};

export const getRangeString = (min, max, currency) => (min === max ? `${currency}${max}` : `${currency}${min}-${currency}${max}`);

export const getRates = (rates, currency) => {
  const payouts = map(({ quote }) => {
    const {
      money: { hostPayout },
    } = quote;
    return hostPayout;
  }, rates);
  if (payouts.length) {
    const min = Math.min(...payouts);
    const max = Math.max(...payouts);
    return getRangeString(min, max, currency);
  }
};

export const getReservationObj = ({
  city,
  country,
  email,
  endDate,
  firstName,
  guestsCount,
  invoiceTel,
  lastName,
  listingId,
  reservationId,
  startDate,
  streetAndNumber,
  token,
  zipCode,
  providerType,
  inquiryId,
  ratePlanId,
  bookerId,
  source,
  isPaymentRequired,
  conversationId,
  isRequestToBook,
}) => ({
  reservation: {
    reservationId,
    listingId,
    checkInDateLocalized: parse(startDate, 'HH:mm', new Date()),
    checkOutDateLocalized: parse(endDate, 'HH:mm', new Date()),
    guestsCount,
    source: 'quotes',
  },
  guest: {
    firstName,
    lastName,
    emails: [email],
    phones: [invoiceTel],
    address: {
      city,
      country,
      street: streetAndNumber,
      zipcode: zipCode,
    },
  },
  policy: {
    privacy: {
      isAccepted: true,
      version: 1,
      dateOfAcceptance: new Date(),
    },
  },
  token,
  providerType,
  isPaymentRequired,
  conversationId,
  isRequestToBook,
  ...(inquiryId && {
    inquiryData: {
      inquiryId,
      ratePlanId,
      bookerId,
      source,
    },
  }),
});

export function debounce(func, timeout = 200) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const parseErrorResponse = (error) => {
  const response = error.response && error.response.data;
  // TODO Temporary until errors are not handled by BE
  if (response?.message === 'Request failed with status code 422') {
    return 'DATES_ARE_UNAVAILABLE';
  }
};

/**
 * Calculates the total number of guests for a reservation.
 *
 * @param {Object} inquiry - An object containing reservation details.
 *
 * @param {Object} [inquiry.numberOfGuests] - An object containing the number of adults, children, and infants.
 * @param {Object} [inquiry.guestsCount] - Total guests accommodation count.
 *
 * @param {number} [inquiry.numberOfGuests.numberOfAdults=0] - The number of adults.
 * @param {number} [inquiry.numberOfGuests.numberOfChildren=0] - The number of children.
 * @param {number} [inquiry.numberOfGuests.numberOfInfants=0] - The number of infants.
 * @param {number} [inquiry.guestsCount=0] - Total number of guests, without children.
 *
 * @returns {number} The total number of guests for the reservation(s).
 */
export const getGuestsCountForReservation = (inquiry) => {
  if (inquiry && hasPath(['numberOfGuests'], inquiry)) {
    const { numberOfGuests } = inquiry;

    return sum([
      numberOfGuests.numberOfAdults || 0,
      numberOfGuests.numberOfChildren || 0,
      numberOfGuests.numberOfInfants || 0,
    ]);
  }

  return (inquiry || {}).guestsCount || 0;
};

export const buildUpdateReservationWithGuestInfoBody = (values, quotes) => {
  const ids = _.keys(values);
  const reservations = _.get(quotes, 'meta.reservations', []);

  return _.reduce(
    ids,
    (acc, id) => {
      const reservation = _.find(reservations, { _id: id });
      const inquiry = _.get(reservation, 'inquiry');

      const payload = {
        reservationId: id,
        conversationId: _.get(reservation, 'conversationId'),
        inquiryId: _.get(reservation, 'inquiryId'),
        source: _.get(inquiry, 'source'),
        ratePlanId: _.get(inquiry, 'rates.ratePlans[0].ratePlan._id'),
        isRequestToBook: Boolean(_.get(inquiry, 'requestToBook')),
        accountId: _.get(quotes, 'meta.account._id', ''),
        bookerId: _.get(quotes, 'meta.booker._id'),
      };

      return acc.concat(payload);
    },
    [],
  );
};
