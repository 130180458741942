import styled from 'styled-components';
import { mobile } from 'constants/css/breakpoints';

export const LayoutTag = styled.div`
  background-repeat: no-repeat;
  background-image:
    linear-gradient(to bottom, #f9f9f9, #efefef),
    linear-gradient(white, white);
  background-size: ${props => (props.multipleBackground
    ? '100% 470px, 100%'
    : '100%, 100%')};
  width: 100vw;
  height: 100vh;
  @media ${mobile} {
    background-size: ${props => (props.multipleBackground
    ? '100% 515px, 100%'
    : '100%, 100%')};
  }

  /* prevent rows to be squashed */
  & .d-flex {
    flex-shrink: 0;
  }
`;
