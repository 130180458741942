import { useEffect } from 'react';
import { connect } from 'react-redux';
import useWindowSize from 'hooks/useWindowSize';
import actions from 'redux/actions';
import {
  DESKTOP,
  LAPTOP,
  LANDSCAPE_MODE_TABLETS,
  MOBILE,
  TABLET,
  lg,
  md,
  sm,
  xxl,
} from 'constants/css/breakpoints';

const { SET_BREAKPOINT } = actions;

export const WindowSizeTracker = ({ breakpoint, setBreakpoint }) => {
  const windowSize = useWindowSize();

  useEffect(() => {
    let newBreakpoint = DESKTOP;

    if (windowSize.innerWidth <= sm) {
      newBreakpoint = MOBILE;
    } else if (windowSize.innerWidth <= md) {
      newBreakpoint = TABLET;
    } else if (windowSize.innerWidth <= lg) {
      newBreakpoint = LAPTOP;
    } else if (windowSize.innerWidth >= lg && windowSize.innerWidth <= xxl) {
      newBreakpoint = LANDSCAPE_MODE_TABLETS;
    }

    if (newBreakpoint !== breakpoint) setBreakpoint(newBreakpoint);
  }, [breakpoint, windowSize.innerWidth, setBreakpoint]);

  return null;
};

const mapStateToProps = state => ({
  breakpoint: state.layout.breakpoint,
});

const mapDispatchToProps = dispatch => ({
  setBreakpoint: breakpoint => dispatch({ type: SET_BREAKPOINT, payload: breakpoint }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WindowSizeTracker);
