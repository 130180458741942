import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { map, path, filter } from 'ramda';
import _ from 'lodash';
import {
  getInvoicePageUrl,
  pluralize,
  convertCurrencySymbol,
  getRates,
  getGuestsCountForReservation,
  getQuotesPageUrl,
} from 'utils/helpers';
import { DESKTOP, LAPTOP, LANDSCAPE_MODE_TABLETS, MOBILE, TABLET } from 'constants/css/breakpoints';
import { MAIN_PAGE } from 'constants/route.constants';
import Gallery from 'App/components/Gallery/Gallery.component';
import ListingCarousel from 'App/components/ListingCarousel/ListingCarousel.component';
import {
  guestsTranslation,
  bedTranslation,
  bathroomTranslation,
  totalPriceTranslation,
  bedroomTranslation,
  estPayoutTranslation,
} from 'utils/translations';
import { listingPageIntlId } from 'utils/intlIds';

import createStyles from '@guestyci/foundation/createStyles';
import OutlineButton from '@guestyci/foundation/OutlineButton';
import useTranslations from '@guestyci/localize/useTranslations';
import TextField from '@guestyci/foundation/TextField';
import { Collapse, Divider, Icon } from '@guestyci/atomic-design/dist/v2/components';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import Layout from '../../../../components/Layout/new/Layout.component';
import ListingMap from '../ListingMap/ListingMap';
import Footer from '../Footer/Footer';
import allAmenities from '../../assets/amenities';
import { Amenity, CloseButton, Listing, MapWrapper, Tag } from './Listing.styled';
import HouseRules from './HouseRules';

const AMENITIES_TO_SHOW = 6;
const amenitiesObj = {};
allAmenities.forEach((amenity) => {
  amenitiesObj[amenity.lowerCase] = amenity.icon;
});

const useClasses = createStyles(({ spacer }) => ({
  header: {
    display: 'grid',
    gap: `${spacer(3)}px`,
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: `0 ${spacer(3)}px`,
  },
  buttonWrapper: {
    flex: 'none',
    alignSelf: 'end',
  },
}));

const Amenities = ({ amenities, isMobile }) => (
  <div
    className={cn('d-flex flex-wrap justify-content-between', {
      'flex-column': isMobile,
    })}
  >
    {amenities.map((amenity) => (
      <Amenity
        className={cn('d-flex flex-column flex-shrink-0 pb-4', {
          'w-100': isMobile,
        })}
        key={amenity}
      >
        <div className="d-flex align-items-center">
          <Icon width={30} height={30} icon={amenitiesObj[amenity.toLowerCase()]} />
          <div className="font-size-lg text-truncate" title={amenity}>
            {amenity}
          </div>
        </div>
        <Divider className="mt-1" />
      </Amenity>
    ))}
  </div>
);

const ListingComponent = ({ breakpoint, history, listing, params, quotes, ...props }) => {
  const [, isSAQGRFlowEnabled] = useFeatureToggle('RES-SAQ-GR-flow');
  const location = useLocation();
  const classes = useClasses();
  const [isAmenitiesCollapsed, setIsAmenitiesCollapsed] = useState(true);
  const [isSummaryCollapsed, setIsSummaryCollapsed] = useState(true);
  const { quotesId, listingId } = params;

  const expireAt = path(['expireAt'], quotes);
  const priceObj = path(
    ['0', 'price'],
    filter((res) => res.listing._id === listingId, path(['meta', 'reservations'], quotes) || []),
  );
  const reservation = filter(
    (res) => res.listing._id === listingId,
    path(['meta', 'reservations'], quotes) || [],
  )[0];
  const inquiry = path(['inquiry'], reservation);

  const price = path(['value'], priceObj);
  const currency = path(['currency'], priceObj);
  const propertyType = path(['propertyType'], listing);
  const summary = path(['publicDescription', 'summary'], listing);
  const ratePlans = path(['rates', 'ratePlans'], inquiry) || [];
  const currentCurrency = currency || ratePlans?.[0]?.quote.money.currency;
  const convertedCurrency = convertCurrencySymbol(currentCurrency);
  const estPayoutRange = useMemo(
    () => getRates(ratePlans, convertedCurrency),
    [ratePlans, convertedCurrency],
  );
  const hasEstPayout = estPayoutRange && estPayoutRange.includes('-');
  const payoutValue = !ratePlans?.length ? `${convertedCurrency}${price}` : estPayoutRange;
  const isPaymentRequired = path(['requirePaymentMethod'], quotes);

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const isPageReadOnly = urlSearchParams.get('readOnly') === 'true';
  const backUrl = urlSearchParams.get('backUrl');

  const translations = [
    bedTranslation,
    bathroomTranslation,
    guestsTranslation,
    totalPriceTranslation,
    estPayoutTranslation,
    bedroomTranslation,
    {
      id: listingPageIntlId('location'),
      d: 'Location',
    },
    {
      id: listingPageIntlId('amenities'),
      d: 'Amenities',
    },
    {
      id: listingPageIntlId('house_rules'),
      d: 'House rules',
    },
    {
      id: listingPageIntlId('read_more'),
      d: 'Read more about the space',
    },
    {
      id: listingPageIntlId('read_less'),
      d: 'Read less',
    },
  ];

  const [
    bedTag,
    bathroomTag,
    guestsTag,
    totalPriceCaption,
    estPayoutCaption,
    bedroomTag,
    locationCaption,
    amenitiesCaption,
    houseRulesCaption,
    readMoreBtn,
    readLessBtn,
  ] = useTranslations(translations);

  const payoutCaption = hasEstPayout ? estPayoutCaption : totalPriceCaption;

  const isMobile = breakpoint === MOBILE;
  const isTablet = breakpoint === TABLET;
  const isDesktop = breakpoint === DESKTOP;
  const isLaptop = breakpoint === LAPTOP;
  const isLandscapeMode = breakpoint === LANDSCAPE_MODE_TABLETS;

  const onClose = isMobile ? () => history.push(MAIN_PAGE) : undefined;

  const lat = path(['publishedAddress', 'lat'], listing) || path(['address', 'lat'], listing);
  const lng = path(['publishedAddress', 'lng'], listing) || path(['address', 'lng'], listing);
  const amenities = path(['amenities'], listing) || [];
  const description = path(['privateDescription', 'directions'], listing) || '';
  const fullAddress =
    path(['publishedAddress', 'full'], listing) || path(['address', 'full'], listing) || '';
  const images = map(
    ({ original, thumbnail }) => original || thumbnail,
    path(['pictures'], listing) || [],
  );
  const locationDescription = path(['privateDescription', 'directions'], listing) || '';
  const beds = path(['beds'], listing);

  const guestsCount = getGuestsCountForReservation(inquiry);

  const tags = [
    propertyType,
    pluralize(guestsTag, guestsCount),
    pluralize(bedroomTag, path(['bedrooms'], listing)),
    ...(beds ? [pluralize(bedTag, beds)] : []),
    pluralize(bathroomTag, path(['bathrooms'], listing)),
  ];
  const title = path(['title'], listing) || '';
  const showHouseRules = path(['showHouseRules'], quotes);
  const houseRules = path(['houseRules'], listing);
  const splittedSummary = summary ? summary.split('.') : [];
  const hasLongSummary = splittedSummary.length > 2;

  return (
    <>
      <Layout.ContainerFluid>
        <div className={classes.header}>
          <div className={classes.buttonWrapper}>
            {isSAQGRFlowEnabled && (
              <Link to={backUrl || getQuotesPageUrl({ quotesId })} className="text-decoration-none">
                <OutlineButton onClick={_.noop}>Back</OutlineButton>
              </Link>
            )}
          </div>
          <Layout.Logo />
        </div>

        {!!listing && !!quotes && (
          <Listing className="d-flex flex-1 flex-column overflow-hidden">
            <div className="w-100 pos-relative">
              {isMobile && (
                <>
                  <Gallery
                    images={images}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    isLandscapeMode={isLandscapeMode}
                  />
                  <CloseButton
                    className="d-flex align-items-center justify-content-left pl-3"
                    onClick={onClose}
                  >
                    <Icon color="gray-dark" width={14} height={14} icon="BtnDeleteMobile" />
                  </CloseButton>
                </>
              )}
              <ListingCarousel
                images={images}
                showArrows={!isMobile}
                isMobile={isMobile}
                isTablet={isTablet}
                isLaptop={isLaptop}
                isLandscapeMode={isLandscapeMode}
              />
            </div>
            <div
              className={cn('d-flex flex-column w-100', {
                'pt-12 pb-12 pl-11 pr-11': isDesktop,
                'p-6': isTablet,
                'pt-6 pr-6 pl-6': isMobile,
              })}
            >
              <div className="d-flex flex-wrap justify-content-between pb--1">
                <TextField variant="h2">{title}</TextField>
                {!isMobile && (
                  <div className="d-flex align-items-baseline">
                    <TextField variant="h2">{payoutValue}</TextField>
                    &nbsp;
                    <div className="color-grayish-blue">{payoutCaption}</div>
                  </div>
                )}
              </div>
              <div className="font-size-lg pt--1">{fullAddress}</div>
              <Divider className="mt-6" />

              {/** Tags */}
              <div
                className={cn('d-flex flex-wrap pt-2', {
                  'flex-column': isMobile,
                })}
              >
                {tags.map((tag, i) => (
                  <Tag key={i} className="pr-4 pt-4 font-size-lg color-black">
                    {tag}
                  </Tag>
                ))}
              </div>

              {/** Description */}
              <div className="font-size-lg pt-6">{description}</div>
              {!!summary?.length && (
                <>
                  <div style={{ whiteSpace: 'pre-line' }}>
                    <TextField>{`${splittedSummary.slice(0, 2).join('.')}.`}</TextField>
                    {hasLongSummary && (
                      <Collapse isOpen={!isSummaryCollapsed}>
                        {`${splittedSummary.slice(2).join('.')}.`}
                      </Collapse>
                    )}
                  </div>
                  {hasLongSummary && (
                    <div
                      className="color-blue font-size-lg cursor-pointer"
                      onClick={() => setIsSummaryCollapsed((prev) => !prev)}
                    >
                      <span className="pr-2">{isSummaryCollapsed ? readMoreBtn : readLessBtn}</span>
                      {isSummaryCollapsed ? (
                        <Icon color="blue" icon="BtnArrowDown" height={10} width={10} />
                      ) : (
                        <Icon color="blue" icon="BtnArrowUp" height={10} width={10} />
                      )}
                    </div>
                  )}
                </>
              )}

              {/** Amenities */}
              {amenities.length > 0 && (
                <div className="d-flex flex-column pt-12">
                  <div className="font-size-21 pb-6">{amenitiesCaption}</div>
                  <Amenities amenities={amenities.slice(0, AMENITIES_TO_SHOW)} isMobile={isMobile} />
                  {amenities.length > AMENITIES_TO_SHOW && (
                    <>
                      <Collapse isOpen={!isAmenitiesCollapsed}>
                        <Amenities amenities={amenities.slice(AMENITIES_TO_SHOW)} isMobile={isMobile} />
                      </Collapse>
                      <div
                        className="color-blue font-size-lg cursor-pointer"
                        onClick={() => setIsAmenitiesCollapsed((prev) => !prev)}
                      >
                        <span className="pr-2">
                          {isAmenitiesCollapsed ? `See all ${amenities.length} amenities` : 'See less'}
                        </span>
                        {isAmenitiesCollapsed ? (
                          <Icon color="blue" icon="BtnArrowDown" height={10} width={10} />
                        ) : (
                          <Icon color="blue" icon="BtnArrowUp" height={10} width={10} />
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}

              {/* House Rules */}
              {showHouseRules && houseRules && (
                <div className="d-flex flex-column pt-12">
                  <div className="font-size-21 pb-4">{houseRulesCaption}</div>
                  <HouseRules houseRules={houseRules} />
                </div>
              )}

              {/** Location */}
              <div className="d-flex flex-column pt-12">
                <div className="font-size-21">{locationCaption}</div>
                <div className="font-size-lg pt-6">{locationDescription}</div>
                <MapWrapper>
                  <ListingMap position={{ lat, lng }} />
                </MapWrapper>
              </div>
            </div>
          </Listing>
        )}
      </Layout.ContainerFluid>

      <Layout.Footer fixed isHidden={isPageReadOnly}>
        <Footer
          expiration={expireAt}
          history={history}
          isMobile={isMobile}
          totalPrice={payoutValue}
          totalPriceCaption={payoutCaption}
          onClick={() => history.push(getInvoicePageUrl({ quotesId, listingId }))}
          isPaymentRequired={isPaymentRequired}
        />
      </Layout.Footer>
    </>
  );
};

ListingComponent.propTypes = {
  push: PropTypes.func.isRequired,
  listing: PropTypes.objectOf(),
  quotes: PropTypes.objectOf(),
};

const mapStateToProps = (state) => ({
  breakpoint: state.layout.breakpoint,
  listing: state.quotes.listing,
  inquiry: state.quotes.inquiry,
  quotes: state.quotes.quotes,
});

export default connect(mapStateToProps, null)(ListingComponent);
