import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { debounce } from 'utils/helpers';

import TextField from '@guestyci/foundation/TextField';
import FormField from '@guestyci/foundation/FormField';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import Input from '@guestyci/foundation/Input';
import PhonePicker from '@guestyci/foundation/PhonePicker';

const Address = ({
  guestInfoText,
  firstNameText,
  lastNameText,
  emailText,
  phoneNumberText,
  streetText,
  cityText,
  zipCodeText,
  isMobile,
  countryText,
  countriesList,
}) => {
  const [filteredOptions, setFilteredOptions] = useState(countriesList);
  const handleSearch = debounce((e) => {
    const { value } = e.target;
    const filtered = countriesList.filter((option) => option.text.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredOptions(filtered);
  });

  return (
    <>
      <TextField variant="h3" className="mt-6">{guestInfoText}</TextField>
      <div className="d-flex mt-3 flex-wrap">
        <div
          className={cn({
            'w-50 pr-2': !isMobile,
            'mt-4 w-100': isMobile,
          })}
        >
          <FormField
            name="firstName"
            label={firstNameText}
            required
          >
            <Input className="m-0" placeholder={firstNameText} />
          </FormField>
        </div>
        <div
          className={cn({
            'w-50 pl-2': !isMobile,
            'mt-4 w-100': isMobile,
          })}
        >
          <FormField
            name="lastName"
            label={lastNameText}
            required
          >
            <Input placeholder={lastNameText} className="m-0" />
          </FormField>
        </div>
      </div>
      <div className="d-flex flex-wrap mt-6">
        <div
          className={cn({
            'w-50 pr-2': !isMobile,
            'mt-4 w-100': isMobile,
          })}
        >
          <FormField
            name="email"
            label={emailText}
            required
          >
            <Input placeholder={emailText} className="m-0" />
          </FormField>
        </div>
        <div
          className={cn({
            'w-50 pl-2': !isMobile,
            'mt-4 w-100': isMobile,
          })}
        >
          <FormField
            id="invoiceTel"
            name="invoiceTel"
            label={phoneNumberText}
            required
          >
            <PhonePicker
              placeholder={phoneNumberText}
              className="m-0"
              withActions={false}
            />
          </FormField>
        </div>
      </div>
      <div className="d-flex flex-wrap mt-6">
        <div
          className={cn({
            'w-50 pr-2': !isMobile,
            'mt-4 w-100': isMobile,
          })}
        >
          <FormField
            name="streetAndNumber"
            label={streetText}
            required
          >
            <Input placeholder="Street and number" className="m-0" />
          </FormField>
        </div>
        <div
          className={cn({
            'w-50 pl-2': !isMobile,
            'mt-4 w-100': isMobile,
          })}
        >
          <FormField name="city" label={cityText} required>
            <Input placeholder={cityText} className="m-0" />
          </FormField>
        </div>
      </div>
      <div className="d-flex flex-wrap mt-6">
        <div
          className={cn({
            'w-50 pr-2': !isMobile,
            'mt-4 w-100': isMobile,
          })}
        >
          <FormField
            name="zipCode"
            label={zipCodeText}
            required
          >
            <Input placeholder={zipCodeText} className="m-0" />
          </FormField>
        </div>
        <div
          className={cn({
            'w-50 pl-2': !isMobile,
            'mt-4 w-100': isMobile,
          })}
        >
          <FormField
            name="country"
            label={countryText}
            required
          >
            <Dropdown
              renderSelected="text"
              className="m-0 bg-white"
              placeholder={countryText}
              searchable
              onSearchQueryChange={handleSearch}
            >
              {filteredOptions.map((option) => (
                <Option value={option} key={option.value}>
                  {option.text}
                </Option>
              ))}
            </Dropdown>
          </FormField>
        </div>
      </div>
    </>
  );
};

Address.propTypes = {
  firstNameText: PropTypes.string.isRequired,
  lastNameText: PropTypes.string.isRequired,
  streetText: PropTypes.string.isRequired,
  cityText: PropTypes.string.isRequired,
  countryText: PropTypes.string.isRequired,
  zipCodeText: PropTypes.string.isRequired,
  emailText: PropTypes.string.isRequired,
  phoneNumberText: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  countriesList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
};

export default Address;
