import React from 'react';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

const MainPage = React.lazy(() => import('./new/Main.page'));
const MainLegacyPage = React.lazy(() => import('./old/Main.page'));

const Main = () => {
  const [isFTLoading, isSAQGRFlowEnabled] = useFeatureToggle('RES-SAQ-GR-flow');

  if (isFTLoading) {
    return null;
  }

  return (
    <React.Suspense fallback={null}>
      {isSAQGRFlowEnabled ? <MainPage /> : <MainLegacyPage />}
    </React.Suspense>
  );
};

export default Main;
