import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ListingCarousel from 'App/components/ListingCarousel/ListingCarousel.component';
import Portal from 'App/components/Portal/Portal.component';

import { Icon } from '@guestyci/atomic-design/dist/v2/components';

import { CloseButton, GalleryBtn, GalleryWrapper } from './Gallery.component.styled';

const Gallery = ({
  images = [], isMobile, isTablet, isLandscapeMode,
}) => {
  const [showGallery, setShowgallery] = useState(false);

  return (<>
    <GalleryBtn
      className="d-flex flex-center font-size-lg border-radius-2 pt-2 pb-2 pr-3 pl-3"
      onClick={() => setShowgallery(true)}
    >
      Gallery
    </GalleryBtn>
    {!!showGallery && <Portal>
      <GalleryWrapper className="w-100 h-100 pos-absolute top-0 left-0 zindex-10">
        <CloseButton
          className="d-flex align-items-center justify-content-left pl-3"
          onClick={() => setShowgallery(false)}
        >
          <Icon color="gray-dark" width={14} height={14} icon="BtnDeleteMobile" />
        </CloseButton>
        <ListingCarousel
          images={images}
          showThumbs
          showArrows={false}
          isMobile={isMobile}
          isTablet={isTablet}
          isLandscapeMode={isLandscapeMode}
        />
      </GalleryWrapper>
                      </Portal>}
          </>
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isLandscapeMode: PropTypes.bool.isRequired,
};

Gallery.defaultProps = {
  images: [],
};

export default Gallery;
