import { merge } from 'ramda';
import _ from 'lodash';
import ActionTypes from 'redux/actions';

const {
  FETCH_QUOTES_SUCCESS,
  FETCH_LISTING_SUCCESS,
} = ActionTypes;

const initialState = {
  quotes: {},
  listingCollection: {},
  reservationCollection: {},
  listing: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUOTES_SUCCESS: {
      return merge(state, {
        quotes: action.payload,
        reservationCollection: _.keyBy(action.payload.meta.reservations, '_id'),
      });
    }

    case FETCH_LISTING_SUCCESS: {
      return {
        ...state,
        listing: action.payload,
        listingCollection: {
          ...state.listingCollection,
          [action.payload._id]: { ...action.payload },
        },
      };
    }

    default:
      return state;
  }
};
