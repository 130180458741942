import React from 'react';

import createStyles from '@guestyci/foundation/createStyles';
import { Col, Row } from '@guestyci/foundation/Layout';

const useStyles = createStyles(() => ({
  title: {
    fontWeight: 700,
  },
}));

const HouseRulesItem = ({ icon, title, desc }) => {
  const classes = useStyles();

  return (
    <Row spacing={2} align="center">
      <img src={icon} alt="icon" />

      <Col>
        <div className={classes.title}>{title}</div>
        {desc && <div>{desc}</div>}
      </Col>
    </Row>
  );
};

export default HouseRulesItem;
