import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as historyPush } from 'connected-react-router';
import { MOBILE } from 'constants/css/breakpoints';
import Layout from 'App/components/Layout/new/Layout.component';
import actions from 'redux/actions';

import Listing from './components/Listing/Listing';
import SimplifiedListings from './components/SimplifiedListings/SimplifiedListings';

const { FETCH_LISTING_REQUEST } = actions;

const ListingPage = ({ breakpoint, getListing, getQuotes, history, match: { params } }) => {
  const isMobile = breakpoint === MOBILE;
  const { quotesId, listingId } = params;

  useEffect(() => {
    // Probably can be refactored
    getListing({ listingId, quotesId });
  }, [getQuotes, getListing, isMobile, quotesId, listingId]);

  return (
    <Layout multipleBackground={false}>
      <Layout.Body>
        <div className="d-flex justify-content-end position-relative">
          <Listing history={history} params={params} />
          {!isMobile && (
            <SimplifiedListings quotesId={quotesId} listingId={listingId} history={history} />
          )}
        </div>
      </Layout.Body>
    </Layout>
  );
};

ListingPage.propTypes = {
  getListing: PropTypes.func,
  getQuotes: PropTypes.func,
  push: PropTypes.func.isRequired,
  quotes: PropTypes.objectOf(),
};

const mapStateToProps = (state) => ({
  breakpoint: state.layout.breakpoint,
});

const mapDispatchToProps = (dispatch) => ({
  getListing: ({ quotesId, listingId }) =>
    dispatch({
      type: FETCH_LISTING_REQUEST,
      payload: { quotesId, listingId },
    }),
  push: (_path) => dispatch(historyPush(_path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListingPage));
