import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TechnicalPage from 'App/pages/TechnicalPage/Technical.page';
import SuccessIcon from 'assets/success.svg';
import { successPageIntlId } from 'utils/intlIds';
import { MOBILE } from 'constants/css/breakpoints';

import useTranslations from '@guestyci/localize/useTranslations';

const SuccessPaidBookingPage = ({ breakpoint }) => {
  const translations = [
    {
      id: successPageIntlId('title'),
      d: 'Thank you',
    },
    {
      id: successPageIntlId('subTitle'),
      d: 'for booking with us!',
    },
    {
      id: successPageIntlId('bottomText'),
      d: 'You will receive an email confirmation shortly',
    },
  ];

  const [title, subTitle, bottomText] = useTranslations(translations);

  const isMobile = breakpoint === MOBILE;

  return (
    <TechnicalPage
      breakpoint={breakpoint}
      Img={SuccessIcon}
      title={isMobile ? title : `${title} ${subTitle}`}
      subTitle={isMobile ? subTitle : ''}
      bottomText={bottomText}
    />
  );
};
SuccessPaidBookingPage.propTypes = {
  breakpoint: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  breakpoint: state.layout.breakpoint,
});

export default connect(mapStateToProps)(SuccessPaidBookingPage);
