import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, root = document.getElementById('portal') }) => ReactDOM.createPortal(
  children,
  root,
);

Portal.propTypes = {
  children: PropTypes.element.isRequired,
  root: PropTypes.node,
};

export default Portal;
