export const ACCOUNTS_URL = '/api/v2/accounts';
export const INVOICE_URL = '/quotes/payment';
export const QUOTES_LISTING_URL = '/quotes/listing';
export const QUOTES_URL = '/quotes';
export const SAND_WORM_URL = '/sand-worm';
export const WITHOUT_PAYMENT_URL = '/without-payment';
// TODO: for testing purposes
// export const STRIPE_SETUP_INTENTS_URL = '/api/v2/stripe/setupIntents';
export const STRIPE_SETUP_INTENTS_URL = '/quotes/payment/token';

export const QS_PARSE_CONFIG = { ignoreQueryPrefix: true };

export const getQuotesListingUrl = ({ quotesId, listingId }) => `/quotes/${quotesId}/listing/${listingId}`;

const env = process.env.REACT_APP_CLUSTER;

export const geBaseUrl = () => `https://app${env !== 'production' ? `-${env}` : ''}.guesty.com/api/public`;
