import React from 'react';
import { path } from 'ramda';

import { LogoTagContainer } from './LogoTag.styled';

export default function LogoTag({ quotes }) {
  const account = path(['meta', 'account'], quotes);
  const logo = account?.companyLogo ? (
    <img src={account.companyLogo} alt={account.name} />
  ) : null;
  return (
    <LogoTagContainer>{logo}</LogoTagContainer>
  );
}
